import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  FormControl,
  Grid,
  OutlinedInput,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import Loader from "@components/Loader";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "@redux/action/index";
import { useNavigate } from "react-router-dom";
import { getImageUrl, postApiReq } from "src/utils/ApiHandlers";
// import { createPlayerAvatarValidation } from "@utils/validation";
import { isYupError, parseYupError } from "@utils/Yup";

const initialState = {
  title: "",
  asset: [{ name: 0, value: "" }],
  fancardImage: "",
  cardType: "",
  totalNFT: 0,
  points: 0
};
const supplyRegx = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

export default function AddAflAvatar() {
  const { id } = useParams();
  const [form, setForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const uploadFile = async (e, index) => {
    let data = new FormData();
    data.append("asset", e.target.files[0]);
    let image = await getImageUrl(data);

    if (index || index === 0) {
      if (errors?.asset?.length) {
        let tempError = errors?.asset?.slice(0);
        tempError[index]["value"] = "";
        setErrors({
          ...errors,
          asset: tempError
        });
      }

      let temp = form.asset.slice(0);
      temp[index]["value"] = image;

      setForm({
        ...form,
        asset: temp
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: image
      });
      setErrors({
        ...errors,
        [e.target.name]: ""
      });
    }
  };
  console.log(form, "form for add avatar");
  const handleSubmit = async () => {
    setIsLoading(true);
    let data = {
      ...form,
      price: Number(form.price),
      totalNFT: Number(form.totalNFT),
      supply: Number(form.totalNFT)
    };
    try {
      setErrors({});
      // await createPlayerAvatarValidation.validate(data, {
      //   abortEarly: false
      // });
      postApiReq(`/admin/upload-player-avatar`, data)
        .then((res) => {
          if (res.status) {
            dispatch(
              showToast({
                message: "AFL Avatar are created successfully",
                type: "success"
              })
            );
            setForm(initialState);
            navigate("/afl-avatar");
            setIsLoading(false);
          } else {
            dispatch(showToast({ message: res.error, type: "error" }));
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ""
    });
  };

  useEffect(() => {
    return setForm(initialState);
  }, []);

  useEffect(() => {
    if (id) {
      setForm({ ...form, teamId: id });
    }
  }, [id]);

  const addAsset = () => {
    let temp = form.asset.slice(0);
    temp.push({ name: form.asset.length, value: "" });
    setForm({
      ...form,
      asset: temp
    });
  };

  const RemoveAsset = (index) => {
    let temp = form.asset.slice(0);
    temp.splice(index, 1);
    setForm({
      ...form,
      asset: temp
    });
  };
  const handleAssetChange = (e, index) => {
    let temp = form.asset.slice(0);
    if (supplyRegx.test(e.target.value) || e.target.value === "") {
      temp[index][e.target.name] = e.target.value;

      setForm({
        ...form,
        asset: temp
      });

      if (errors?.asset) {
        let tempError = form.asset.slice(0);
        tempError[index][e.target.name] = "";
        setErrors({
          ...errors,
          asset: tempError
        });
      }
    }
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Typography className={styles.heading}>
          {"Create Avatar For Player"}
        </Typography>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box ml={10}>
                <Grid container spacing={3}>
                  <Grid mt={-2} item xs={12} lg={5}>
                    <TextField
                      error={errors.title}
                      margin="normal"
                      fullWidth
                      type="text"
                      label="Title"
                      name="title"
                      helperText={errors.title}
                      onChange={handleChange}
                      value={form.title}
                    />
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <FormControl fullWidth error={errors.sportId}>
                      <InputLabel id="demo-simple-select-label">
                        Card Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="cardType"
                        label="Card Type"
                        value={form.cardType}
                        input={<OutlinedInput label="Card Type" />}
                        onChange={handleChange}
                      >
                        <MenuItem value={"BLUE"}>Blue</MenuItem>
                        <MenuItem value={"SILVER"}>Silver</MenuItem>

                        <MenuItem value={"GOLD"}>Gold</MenuItem>

                        <MenuItem value={"BLACK"}>Black</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid mt={-2} item xs={12} lg={5}>
                    <TextField
                      error={errors.totalNFT}
                      margin="normal"
                      fullWidth
                      type="text"
                      label="Supply"
                      name="totalNFT"
                      helperText={errors.totalNFT}
                      onChange={handleChange}
                      value={form.totalNFT}
                    />
                  </Grid>
                  <Grid mt={-2} item xs={12} lg={5}>
                    <TextField
                      disabled
                      focus
                      margin="normal"
                      fullWidth
                      type="text"
                      label="Points"
                      name="points"
                      value={0}
                    />
                  </Grid>

                  <Grid item xs={12} lg={5} className={styles.imagesContainer}>
                    <Button
                      className={styles.uploadButtonOne}
                      variant="outlined"
                      component="label"
                    >
                      Upload Avatar Image
                      <input
                        type="file"
                        name="fancardImage"
                        onChange={(e) => uploadFile(e)}
                        hidden
                      />
                    </Button>
                    {form.fancardImage && (
                      <img
                        className={styles.image}
                        src={form.fancardImage}
                        alt="fanImage"
                      />
                    )}
                    {errors.fancardImage && (
                      <small className="errormsg">{errors.fancardImage}</small>
                    )}
                  </Grid>
                  {form.asset.map((item, index) => (
                    <Grid
                      mt={5}
                      key={index}
                      container
                      gap={2}
                      alignItems="flex-start"
                    >
                      <Grid item xs={12} lg={3}>
                        <TextField
                          margin="normal"
                          error={errors.asset?.[index]?.name}
                          fullWidth
                          type="text"
                          label="Asset order"
                          name="name"
                          helperText={errors.asset?.[index]?.name}
                          value={item.name}
                          onChange={(e) => handleAssetChange(e, index)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        className={styles.imagesContainer}
                      >
                        <Button
                          sx={{ mt: 2 }}
                          className={styles.uploadButtonOne}
                          variant="outlined"
                          component="label"
                        >
                          Upload implementation Image
                          <input
                            type="file"
                            name="asset"
                            onChange={(e) => uploadFile(e, index)}
                            hidden
                          />
                        </Button>
                        {errors.asset?.[index]?.value && (
                          <small className="errormsg">
                            {errors.asset?.[index]?.value}
                          </small>
                        )}
                        {item.value && (
                          <img
                            className={styles.image}
                            src={item.value}
                            alt="fanImage"
                          />
                        )}
                      </Grid>
                      {index > 0 && (
                        <Typography
                          onClick={() => RemoveAsset(index)}
                          className={styles.removeButton}
                        >
                          Remove asset
                        </Typography>
                      )}
                    </Grid>
                  ))}
                  <Grid item xs={11} align="left">
                    <Typography className={styles.addButton} onClick={addAsset}>
                      Add more asset
                    </Typography>
                  </Grid>
                  <Grid item xs={11} align="right">
                    <Button
                      size="large"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Loader isLoading={isLoading} />
      </TableContainer>
    </React.Fragment>
  );
}
